<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-400px">
            <div>
                <p class="title">중복확인</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light">
                <table class="tbl-light-list" v-if="pop.total>0">
                    <colgroup>
                        <col width="*">
                        <col width="120">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>고객사</th>
                            <th>등록일</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(irow, i) in pop.list" :key="i">
                            <td><router-link :to="{ name:'customerManagement-customerDBViewTab1-idx', params:{ idx:irow.idx } }" target="_blank"><span class="btn-view">{{ irow.kname }}</span></router-link></td>
                            <td>{{ irow.wdate }}</td>
                        </tr>
                    </tbody>
                </table>
                <div v-else class="pop-body">
                    <p>고객사 등록이 가능합니다.</p>
                    <button type="button" @click="pop.onSuccess" class="btn-default dp-inblock">확인</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'

export default {
    name: 'DoubleCheck1Popup',
    props: {
        modelValue: {
            type: String,
            default: "N",
        },
        companyname: {
            type: String,
            default: "",
        }
    },
    emits: [ 'update:modelValue' ],
    setup(props, {emit}) {
        const toast = useToast()

        const pop = reactive({
            cname : "",
            list  : "",
            total : 0,

            doSearch: () => {
                let params = {
                    cname : pop.cname
                };

                axios.get("/rest/customermgr/getLikeCustomerList", { params : params}).then((res) => {
                    if( res.data.err == 0 ){
                        pop.list  = res.data.list;
                        pop.total = res.data.total;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            onSuccess: () => {
                emit('update:modelValue', 'Y');
                emit('close');
            },

            onClose: () => {
                emit('update:modelValue', 'N');
                emit('close');
            }
        });

        onMounted(() => {
            // Mounted
            pop.cname = props.companyname;

            pop.doSearch();
        });

        return {pop};
    }
}
</script>
<style lang="scss" scoped>
.pop-body { 
    text-align: center;
    p {
        font-size:18px; padding:20px 0;
    }
}
</style>