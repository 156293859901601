<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CustomerStageTablePopup v-if="pop.isShowStep" @close="pop.isShowStep=false"></CustomerStageTablePopup>
        <DoubleCheck1Popup :companyname="pop.cname" v-model="pop.isAble" v-if="pop.isShowCheck" v-on:close="pop.hideCheck"></DoubleCheck1Popup>

      <CarrotTitle title="단계별 고객 관리 현황">
        <div class="btn-ibbox title-btnbox float-right">


        </div>
        - 각 고객사의 마지막 고객사 일지를 작성한 인원을 해당 고객사의 담당자로 간주합니다.<br>
        - 검색일 기준, 전년도 이후 작성된 고객사 일지가 있는 고객사만 노출합니다.<br>
        - 1단계 (현재고객사) 고객사는 작성된 고객사 일지가 없어도 노출하며, 9단계 고객사는 일지와 상관없이 노출하지 않습니다.<br>
        <span class="bold">- 노출되지 않는 고객사 (장기 미컨택 고객사) 정보는 excel 다운로드를 통해서 확인 가능합니다.<br><br>
        - 기준 컨택빈도 : <span class="color-1">● 3개월이내 컨택이력 없음</span> / <span class="color-2">● 3개월이내 컨택이력 있음</span> / <span class="color-3">● 한달 이내 컨택이력 있음</span>
        </span>
        <br><br>

        <div class="float-left">
       <span>
          부문/본부
          <CarrotSector  v-model="view.idx_sector" class="w-200px"></CarrotSector>
          <CarrotDept  :idx_sector="view.idx_sector" v-model="view.idx_office" class="w-200px"></CarrotDept>
        </span>
          <span>
          팀
          <CarrotTeam :idx_sector="view.idx_sector"  :idx_office="view.idx_office" v-model="view.idx_team" class="w-200px"></CarrotTeam>
        </span>
          <span>
          담당자
         <CarrotStaff :idx_office="view.idx_office" :idx_team="view.idx_team" v-model="view.idx_staff" class="w-200px"></CarrotStaff>


        </span>
          <span class="float-right ml-5">
          <button class="btn-default " @click="view.doSearch">검색</button>
          </span>
        </div>
        <button class="btn-default h-30px ml-5 float-right mt-20" @click="view.downExcel">장기 미컨택 고객사 Excel 다운로드</button>


      </CarrotTitle>


        <div class="board">
            <div class="mt-40">
                <div>
                    <table class="table-row">
                        <colgroup>
                            <col width="140">
                            <col width="140">
                            <col width="60">
                            <col width="80">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>팀</th>
                                <th colspan="4">단계별고객사현황</th>
                            </tr>
                        </tbody>
                      <tbody v-for="(irow, i) in view.list" :key="i">

                      <!-- 1단계 -->
                        <tr>
                          <th rowspan="16">{{ irow.teamName }}</th>
                          <td rowspan="3"> 1단계 </td>
                          <td rowspan="3"> {{ irow.firstStepCnt }} </td>
                          <td>
                            <span class="color-1">● {{ irow.firstStepNoContact3M }} </span>
                          </td>
                          <td>
                            <span> {{ irow.firstStepNoContact3MCompanys }} </span>
                            <br>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="color-2">● {{ irow.firstStepContact3M }} </span>
                          </td>
                          <td>
                            <span> {{ irow.firstStepContact3MCompanys }} </span>
                            <br>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="color-3">● {{ irow.firstStepContact1M }} </span>
                          </td>
                          <td>
                            <span> {{ irow.firstStepContact1MCompanys }} </span>
                          </td>
                        </tr>

                      <!-- 0단계 -->
                        <tr>
                          <td rowspan="3"> 0단계 </td>
                          <td rowspan="3"> {{ irow.zeroStepCnt }} </td>
                          <td>
                            <span class="color-1">● {{ irow.zeroStepNoContact3M }} </span>
                          </td>
                          <td>
                            <span> {{ irow.zeroStepNoContact3MCompanys }} </span>
                          </td>
                        </tr>
                      <tr>
                        <td>
                          <span class="color-2">● {{ irow.zeroStepContact3M }} </span>
                        </td>
                        <td>
                          <span> {{ irow.zeroStepContact3MCompanys }} </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span class="color-3">● {{ irow.zeroStepContact1M }} </span>
                        </td>
                        <td>
                          <span> {{ irow.zeroStepContact1MCompanys }} </span>
                        </td>
                      </tr>

                      <!-- 2단계 -->
                        <tr>
                          <td rowspan="3"> 2단계 </td>
                          <td rowspan="3"> {{ irow.secondStepCnt }} </td>
                          <td>
                            <span class="color-1">● {{ irow.secondStepNoContact3M }} </span>
                          </td>
                          <td>
                            <span> {{ irow.secondStepNoContact3MCompanys }} </span>
                          </td>
                        </tr>
                      <tr>
                        <td>
                          <span class="color-2">● {{ irow.secondStepContact3M }} </span>
                        </td>
                        <td>
                          <span> {{ irow.secondStepContact3MCompanys }} </span>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span class="color-3">● {{ irow.secondStepContact1M }} </span>
                        </td>
                        <td>
                          <span> {{ irow.secondStepContact1MCompanys }} </span>
                        </td>
                      </tr>

                      <!-- 3단계 -->
                        <tr>
                          <td rowspan="3"> 3단계 </td>
                          <td rowspan="3"> {{ irow.thirdStepCnt }} </td>
                          <td>
                            <span class="color-1">● {{ irow.thirdStepNoContact3M }} </span>
                          </td>
                          <td>
                            <span class="color-1"> {{ irow.thirdStepNoContact3MCompanys }} </span>
                          </td>
                        </tr>
                      <tr>
                        <td>
                          <span class="color-2">● {{ irow.thirdStepContact3M }} </span>
                        </td>
                        <td>
                          <span class="color-2"> {{ irow.thirdStepContact3MCompanys }} </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span class="color-3">● {{ irow.thirdStepContact1M }} </span>
                        </td>
                        <td>
                          <span class="color-3"> {{ irow.thirdStepContact1MCompanys }} </span>
                        </td>
                      </tr>



                        <tr>
                          <td>연간 유입 고객 </td>
                          <td>{{irow.firstStepUpdateCnt}}</td>
                          <td> </td>
                          <td> </td>
                        </tr>
                        <tr>
                          <td>연간 이탈 고객 </td>
                          <td>{{irow.zeroStepUpdateCnt}}</td>
                          <td> </td>
                          <td> </td>
                        </tr>
                      </tbody>

                    </table>
                    <button class="btn-default float-left mt-30" @click="view.doCancel">취소</button>
                    <button class="btn-default float-right mt-30" @click="view.doSubmit">등록</button>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src

import CarrotDept from "@/components/common/CarrotDept.vue";
import CarrotSector from "@/components/common/CarrotSector";
import CarrotTeam from "@/components/common/CarrotTeam.vue";
import CarrotStaff from "@/components/common/CarrotStaff.vue";
import {onMounted, reactive} from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2'
import CustomerStageTablePopup from '@/components/popup/customerManagement/CustomerStageTablePopup.vue'
import DoubleCheck1Popup from '@/components/popup/customerManagement/DoubleCheck1Popup.vue'



export default {
    layout:"customerManagement",
    components: {
        CustomerStageTablePopup, 
        DoubleCheck1Popup,
      CarrotDept,
      CarrotTeam,
      CarrotStaff,
      CarrotSector,
    },
    setup() {
        const router = new useRouter();
        const toast = useToast()

        const pop = reactive({
            // Popup 노출 여부
            cname : "",
            isAble : "",
            isShowStep : false,
            isShowCheck: false,

            showStep : () => {
                pop.isShowStep = true;
            },

            showCheck : () => {
                pop.cname = view.cname;
                if( !pop.cname.length ){
                    toast.error("고객사명을 입력해주세요.");
                    return;
                }
                pop.isShowCheck = true;
            },

            hideCheck : () => {
                pop.isShowCheck = false;
                console.log(pop.isAble);
            }
        });

        const view = reactive({
            cname    : "",
            ename    : "",
            zipcode  : "",
            addr     : "",
            addr_sub : "",
            ceo_name : "",
            bizno    : "",
            step     : "",
            company_type  : "",
            industry_type : "",
            special_memo  : "",
            idx_sector: 0,
            idx_office: 0,
            idx_team: 0,
            idx_staff: 0,
          downExcel : () => {

            // http://local.intranet.mxm.kr
            // location.href = "https://intranet.mxm.kr/excel/education_applicant_list?idx=" + pop.idx;
            location.href = "https://api.carrotians.net/excel/customer_status_excel_list";
          },
            doOnlyEng : () => {
                console.log(view.ename);
                // view.ename = view.ename.replace(/[^a-zA-Z ]/gi, '');
                // console.log(view.ename);
            },
          doSearch : () => {
            let params = {
              page : view.page,
              idx_sector: view.idx_sector,
              idx_office: view.idx_office,
              idx_team: view.idx_team,
              idx_staff: view.idx_staff
            };

            axios.get("/rest/customermgr/getCustomerDBStepList", { params : params }).then((res) => {
              if( res.data.err == 0 ){
                view.list  = res.data.list;
                view.total = res.data.total;

              } else {
                if(res.data.err_msg) toast.error(res.data.err_msg);
              }
            });
          },

            doSubmit : () => {
                let params = {
                    cname    : view.cname,
                    ename    : view.ename,
                    zipcode  : view.zipcode,
                    addr     : view.addr,
                    addr_sub : view.addr_sub,
                    ceo_name : view.ceo_name,
                    bizno    : view.bizno,
                    step     : view.step,
                    company_type  : view.company_type,
                    industry_type : view.industry_type,
                    special_memo  : view.special_memo
                };

                if( !view.cname.length ){
                    toast.error("고객사명을 입력해주세요.");
                    return;
                }
                if( !view.ename.length ){
                  toast.error("고객사 영문명을 입력해주세요.");
                  return;
                }
                if( pop.isAble == "N" || pop.isAble == "" ){
                    toast.error("고객사명 중복확인을 해주세요.");
                    return;
                }
                if( !view.addr_sub ){
                  toast.error("주소를 입력해주세요.");
                  return;
                }
                if( !view.company_type || !view.industry_type || !view.special_memo ){
                    toast.error("필수 입력 항목을 작성하세요.");
                    return;
                }

                axios.post("/rest/customermgr/addCustomer", params).then((res) => {
                    if( res.data.err == 0 ){
                        router.push({
                            name : "customerManagement-customerDBList"
                        });
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doCancel: () => {
                Swal.fire({
                    title:'등록을 취소하시겠습니까? 입력하신 내용이 사라집니다.',
                    showCancelButton: true
                }).then((res) => {
                    if (res.isConfirmed) {
                        router.go(-1);
                    }
                });
            }
        });


      onMounted(() => {

        view.doSearch();
      });
        return {pop, view};
    }
}
</script>

<style lang="scss" scoped>
</style>